import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { FaHtml5 } from 'react-icons/fa';
// import { FaCss3 } from 'react-icons/fa';
// import { FaLess } from 'react-icons/fa';
// import { FaSass } from 'react-icons/fa';
// import { FaPhp } from 'react-icons/fa';
// //MySql
// //jQuery
// import { FaJs } from 'react-icons/fa';
// import { FaGit } from 'react-icons/fa';
// import { FaBitbucket } from 'react-icons/fa';
// import { FaJira } from 'react-icons/fa';
// import { FaLaravel } from 'react-icons/fa';
// import { FaWordpress } from 'react-icons/fa';
// import { FaHubspot } from 'react-icons/fa';
// import { FaDrupal } from 'react-icons/fa';
// import { FaJoomla } from 'react-icons/fa';
// import { FaAdobe } from 'react-icons/fa';


class IndexPage extends React.Component {
  render() {

    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <nav>
          <ul>
              <li className="home"><img src="images/logo.png" className="logo" height="35" alt="Logo"/></li>
              <li className="one">Home</li>
              <li className="two">About</li>
              <li className="three">Portfolio</li>
              <li className="four">Contact</li>
          </ul>
        </nav>
        <div id="home" classname="bgimg-1">
          <div className="top">
              <h1><span>M</span>att <span>B</span>ayliss</h1>
              <p>Web Developer, Creative Writer / Designer based in Birmingham, UK.</p>
          </div>
        </div>
        <div id="contact" classname="bgimg-1">
          <div classname="top">
              <h1><span>M</span>att <span>B</span>ayliss</h1>
              <p>Web Developer, Creative Writer / Designer based in Birmingham, UK.</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
